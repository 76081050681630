import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//引入store状态管理，目的是为了获取其中存储的token：
import store from '@/store'
//引入store类型：
import type { StateAll } from '@/store'
//后续会将store的state断言成StateAll类型：(store.state as StateAll)

import { ElMessage } from "element-plus";

//引入lodash：
import _ from 'lodash'



//通过路由懒加载的方式引入：
const Login = () => import('@/views/Login/Login.vue');
const Home = () => import('@/views/Home/Home.vue');
const Search = () => import('@/views/Search/Search.vue');
const Mind = () => import('@/views/Mind/Mind.vue');
const About = () => import('@/views/About/About.vue');
const WxCallback = () => import('@/views/Login/components/WxCallback.vue');
const User = () => import('@/views/User/User.vue');

const AppInfo = () => import('@/views/AppInfo/AppInfo.vue');

//路由元信息类型注解：
declare module 'vue-router' {
  interface RouteMeta {
    menu?: boolean
    title?: string
    icon?: string
    auth?: boolean
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // 选择home一级路由后重定向到Search组件页面(Search就是二级路由的第一个页面)：
    // redirect: '/searches',
    meta: {
      menu: true,
      title: '词源秘境',  //网站名
      icon: 'document-copy',
      auth: false  //表示不需要权限就能访问
    },
    //home的二级路由：
    children: [
      {
        path: 'searches',
        name: 'searches',
        component: Search,
        meta: {
          menu: true,
          title: '搜索界面',
          icon: 'calendar',
          auth: false
        },
      },
      {
        path: 'minds',
        name: 'minds',
        component: Mind,
        meta: {
          menu: true,
          title: '词源脑图',
          icon: 'warning',
          auth: true,
        }
      },
      {
        path: 'about',
        name: 'about',
        component: About,
        meta: {
          menu: true,
          title: '关于我们',
          icon: 'document-add',
          auth: false,
        }
      },
      {
        path: 'appInfo',
        name: 'appInfo',
        component: AppInfo,
        meta: {
          menu: true,
          title: 'app应用',
          icon: 'document-add',
          auth: false,
        }
      },
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
          menu: true,
          title: '个人中心',
          icon: 'document-add',
          auth: true,
        }
      },
    ]
  }, {
    path: '/wxCallback',
    name: 'WxCallback',
    component: WxCallback,
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫：决定路由是否可以被访问
router.beforeEach((to, from, next) => {
  //获取状态中的token：
  const token = (store.state as StateAll).users.token;
  const infos = (store.state as StateAll).users.userInfos;

  //如果目标路由需要权限：to.meta.auth表示：目标路由下的meta下的auth值
  if (to.meta.auth && _.isEmpty(infos)) {  //_.isEmpty：使用loadash封装的方法来判空
    if (token) {  //如果有token：可继续
      //调用请求，校验token是否合法：
      store.dispatch('users/infos').then((res) => {
        //如果请求后响应状态码为0，校验通过，保存响应的用户infos便于在要进入路由中使用，并允许导航继续
        if (res.data.status === 200) {
          //调用方法，重新更新保存响应的用户infos：
          store.commit('users/updateInfos', res.data.data)//保存响应的用户infos
          next()
        } else {
          ElMessage.warning("token错误或过期，请重新登录")
          next('/login');
        }
      });
    }
    else {  //token校验不通过，说明token是伪造的或者token过期或者其他token问题，去重新登录
      ElMessage.warning('请登录后再试试吧~')
      next('/login');
    }
    // } else if (to.path === '/passport/wx/wxCallback') {
    //   // 如果目标路由是'/passport/wx/wxCallback'，则阻止跳转  
    //   next(false); // 阻止路由跳转  
  } else {
    if (token && to.path === '/login') {
      next('/');
    }
    else {
      next();
    }
  }
})

export default router